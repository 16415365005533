import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import AOS from "aos";

import { useAosApply } from "@common/hooks";
import { AppProvider } from "@common/app-provider";

// Note: Don't change the order
import "@common/theme/root.css";
import "@common/theme/global.css";
import "@common/theme/text.theme.css";
import "@common/theme/title.theme.css";
import "@common/theme/global.override.css";

import Builder from "@builder/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

const RenderApp = () => {
  const location = useLocation();

  useAosApply(location.pathname);

  useEffect(() => {
    AOS.init({
      offset: 100,
    });
  }, []);

  return <Builder />;
};

root.render(
  <BrowserRouter>
    <AppProvider>
      <RenderApp />
    </AppProvider>
  </BrowserRouter>
);
