import React from "react";

import { IoLogoWhatsapp } from "react-icons/io";

import styles from "./style.module.css";

const WHATSAPP_NUMBER = "9360375911";
const WHATSAPP_MESSAGE =
  "Hi there! I would like to know more about your services.";

export const WhatsApp = ({
  className,
  number = WHATSAPP_NUMBER,
  message = WHATSAPP_MESSAGE,
}) => {
  return (
    <React.Fragment>
      <a
        href={`https://wa.me/${number}?text=${encodeURIComponent(message)}`}
        target="_blank"
        rel="noreferrer"
        className={`${styles.whatsappContainer} ${className}`}
      >
        <IoLogoWhatsapp className={styles.icon} />
      </a>
    </React.Fragment>
  );
};
