import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MENU_LIST } from "../constants";

import styles from "./dropdown.style.module.css";

export const Dropdown = () => {
  const navigate = useNavigate();
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const location = useLocation();

  const handleMouseEnter = (e) => {
    const dropdownContainer = e.currentTarget.querySelector(
      `.${styles.dropdownContainer}`
    );
    if (dropdownContainer) {
      const fullHeight = dropdownContainer.scrollHeight;
      setDropdownHeight(fullHeight);
    }
  };

  const handleMouseLeave = () => {
    setDropdownHeight(0);
  };

  const handleConnectClick = (menu) => {
    if (menu?.scrollTo) {
      let path = menu.path;
      if (menu.path === "/connect") {
        path = "/";
      }
      navigate(path, { state: { scrollToConnect: true, id: "connect" } });
    } else {
      navigate(menu.path, { state: { scrollToConnect: false, id: null } });
    }
  };

  return (
    <div className={styles.menuContainer}>
      {MENU_LIST.map((menu) => {
        let isActive = location.pathname === menu.path;
        if (menu.path === "/" && location.state?.id === "connect") {
          isActive = false;
        }
        if (menu.path === "/connect" && location.state?.id === "connect") {
          isActive = true;
        }
        return (
          <div
            className={styles.dropdownMenu}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              "--dropdownHeight": `${dropdownHeight}px`,
            }}
          >
            {/* ---------- menus ---------- */}

            <div
              className={`text-18 ${styles.menuItem} ${
                isActive && styles.active
              }`}
              onClick={() => {
                handleConnectClick(menu);
              }}
            >
              {menu.name}
            </div>

            {/* ---------- dropdown menus ---------- */}
            {menu?.dropdown && (
              <div className={styles.dropdownContainer}>
                <div className={styles.section}>
                  {menu.dropdown.map((dropdown) => {
                    return (
                      <div
                        className={`text-18 ${styles.menuItem}`}
                        onClick={() => {
                          navigate(dropdown.path);
                        }}
                      >
                        {dropdown.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const scrollToConnectSection = (id) => {
  // const connectSection = document.getElementById(id);
  // if (connectSection) {
  //   connectSection.scrollIntoView({ behavior: "smooth" });
  // }

  let delay = 2000;

  const connectSection = document.getElementById(id);

  if (connectSection) {
    const scrollPosition = connectSection.offsetTop;

    // Set the initial scroll position
    let start = window.scrollY;
    let distance = scrollPosition - start;
    let startTime = null;

    // Custom easing function
    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    // Function to handle the scroll animation
    const animateScroll = (timestamp) => {
      if (!startTime) startTime = timestamp;

      const timeElapsed = timestamp - startTime;
      const progress = Math.min(timeElapsed / delay, 1); // Ensure the animation doesn't go past the duration
      const easedProgress = easeInOutQuad(progress);

      window.scrollTo(0, start + distance * easedProgress);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    // Delay the scroll animation
    setTimeout(() => {
      requestAnimationFrame(animateScroll);
    }, 200);
  }
};
