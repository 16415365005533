import React from "react";
import { Route, Routes } from "react-router-dom";

import { SuspenseLazyLoader } from "@common/lazy-loader/suspense";

import "./theme.css";
import { Navbar } from "@components/navbar";
import { WhatsApp } from "@components/whatsapp";

// --------- Lazy-loaded components ----------
const NotFound = SuspenseLazyLoader(() => import("@components/page-not-found"));
const HomePage = SuspenseLazyLoader(() => import("./pages/home"));
const ExpertisePage = SuspenseLazyLoader(() => import("./pages/expertise"));
const InsightsPage = SuspenseLazyLoader(() => import("./pages/insights"));

// -------------------------------------------

export default function Builder() {
  return (
    <div className="studio-theme">
      <Navbar />
      <WhatsApp number="9840717144" />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/expertise" element={<ExpertisePage />} />
        <Route path="/insights" element={<InsightsPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
